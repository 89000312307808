@font-face {
  font-family: 'LaNord';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('fonts/LaNord-SemiLight.woff2') format('woff2');
}

@layer utilities {
  .h0 {
    font-size: clamp(85px, 3vw, 116px);
    line-height: 1;
  }

  .h1 {
    font-size: clamp(30px, 3vw, 40px);
    line-height: 1;
  }

  .h2 {
    font-size: clamp(22px, 3vw, 32px);
    line-height: 1.2;
  }

  .h3 {
    font-size: clamp(22px, 3vw, 26px);
  }

  .h3-sm {
    font-size: clamp(18px, 1.5vw, 26px);
  }

  .text-underline {
    @apply underline decoration-2 underline-offset-[3px];
  }
}

.wysiwyg {
  p {
    @apply my-2;
  }

  a {
    @apply underline decoration-[0.1em] transition-colors hover:text-blue;
  }

  ul {
    @apply my-8 list-disc list-inside;
  }

  ol {
    @apply my-8 list-decimal list-inside;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply underline decoration-[0.1em] my-8;
    font-size: clamp(22px, 3vw, 32px);
    line-height: 1.2;
  }
}

.wysiwyg-article {
  img {
    @apply block my-16 xl:my-24;
  }

  p,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply md:px-8 xl:px-24;
  }
}
