:root {
  --site-logo-height: 16.930489vw;
  --site-header-height: 60px;
  --site-nav-height: 355px;
  --border-radius: 10px;

  @screen md {
    --border-radius: 20px;
  }
}

html.has-modal {
  @apply overflow-hidden;

  .site-header,
  .site-nav {
    @apply opacity-0 pointer-events-none transition-opacity;
  }
}

html.has-nav {
  @apply overflow-hidden;
}

.container {
  @apply px-[10px] md:px-5;
}

.site-wrapper {
  @apply relative z-1 mt-4;

  @screen md {
    margin-top: calc((var(--site-nav-height) + 2vw) * -1);
    transform: translate3d(0, 2vw, 0);
  }

  &.no-pull-up {
    margin-top: calc(var(--site-nav-height) * -1);
  }
}

.site-header {
  @screen md {
    top: calc(var(--site-logo-height) * -1);
    transform: translate3d(0, 2vw, 0);
  }
}

.site-nav {
  @screen md {
    min-height: var(--site-nav-height);
    transform: translate3d(0, 2vw, 0);
  }
}

.site-header,
.site-nav,
.site-wrapper {
  transition: transform 0.5s ease-in-out;
}

.site-nav,
.site-wrapper {
  transition-delay: 0.2s;
}

.is-init {
  .site-header,
  .site-nav,
  .site-wrapper {
    transform: translateY(0);
  }
}

.stuck-logo {
  @apply opacity-0 transition-opacity duration-500 pointer-events-none;

  .is-stuck + .site-nav & {
    @apply opacity-100 pointer-events-auto;
  }
}

.site-menu.is-active {
  @apply grid overflow-scroll-touch overflow-scroll;

  &:before {
    @apply w-screen h-screen z-0 pointer-events-none inset-0 fixed;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95) 25%, rgba(255, 255, 255, 0) 100%);
    content: '';
  }
}

.site-menu {
  @screen md {
    &:before {
      @apply w-screen h-screen z-0 pointer-events-none inset-0 fixed transition-opacity duration-[600ms] opacity-0 hidden;
      content: '';
      background: linear-gradient(
        to bottom,
        hsl(0, 0%, 100%) 0%,
        hsla(0, 0%, 100%, 0.987) 8.1%,
        hsla(0, 0%, 100%, 0.951) 15.5%,
        hsla(0, 0%, 100%, 0.896) 22.5%,
        hsla(0, 0%, 100%, 0.825) 29%,
        hsla(0, 0%, 100%, 0.741) 35.3%,
        hsla(0, 0%, 100%, 0.648) 41.2%,
        hsla(0, 0%, 100%, 0.55) 47.1%,
        hsla(0, 0%, 100%, 0.45) 52.9%,
        hsla(0, 0%, 100%, 0.352) 58.8%,
        hsla(0, 0%, 100%, 0.259) 64.7%,
        hsla(0, 0%, 100%, 0.175) 71%,
        hsla(0, 0%, 100%, 0.104) 77.5%,
        hsla(0, 0%, 100%, 0.049) 84.5%,
        hsla(0, 0%, 100%, 0.013) 91.9%,
        hsla(0, 0%, 100%, 0) 100%
      );
    }
  }

  &.has-active {
    /* .site-menu-item:not(.is-active) { */
    /*   @apply opacity-30; */
    /* } */

    /* &:before { */
    /*   @apply opacity-75; */
    /* } */
  }
}

.site-menu-item {
  &.is-active {
    .site-menu-sub {
      @apply flex;
    }
  }

  a {
    span {
      @apply transition-transform;
    }

    &.rotate-left:hover span {
      @apply -rotate-[8deg];
    }

    &.rotate-right:hover span {
      @apply rotate-[8deg];
    }
  }
}

.section-space-y {
  margin-bottom: clamp(80px, 20vw, 125px);
  margin-top: clamp(80px, 20vw, 100px);
}

.marquee {
  @apply overflow-hidden;
}

.marquee-scroll {
  @apply h0 whitespace-nowrap space-x-[10vw] leading-none;
}

.product-image-modal {
  @apply p-[10px] md:p-5 bg-white fixed inset-0 z-40 overflow-scroll-touch overflow-scroll grid gap-[10px] md:gap-5 pointer-events-none opacity-0 transition-opacity duration-300;

  &.is-active {
    @apply opacity-100 pointer-events-auto;
  }
}

.slider-button {
  @apply transition-colors hover:text-blue;
}

html,
.has-color-toggle,
.has-color-toggle-bg,
.slider-button-prev circle,
.slider-button-next circle,
.slider-button-prev path,
.slider-button-next path {
  @apply transition-colors duration-500;
}

.is-inverted {
  @apply bg-black;

  .has-color-toggle {
    @apply text-white;
  }

  .has-color-toggle-bg {
    @apply bg-white;
  }

  .slider-button-prev svg,
  .slider-button-next svg {
    circle {
      fill: white;
    }

    path {
      stroke: black;
    }
  }
}
